html,
body {
  height: 100%;
  margin: 0;
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

code {
  font-family: monospace;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

iframe#launcher {
  display: none; /* Hide the Zendesk widget launcher */
}

*:focus {
  outline: none;
}

*:not(svg),
*:not(svg)::after,
*:not(svg)::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

*::selection {
  background: var(--chakra-colors-background-primary);
  color: var(--chakra-colors-text-onContrast);
}

* {
  -webkit-font-smoothing: antialiased;
}

.conversation-link {
  color: var(--chakra-colors-background-primary);
  text-decoration: underline;
}

/* Animations */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-719deg);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
